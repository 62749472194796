<template>
  <div>
    <!-- 账号登录 -->
    <el-form ref="thirdForm" v-if="numOrSms" :model="formData" :rules="rules">
      <el-form-item prop="account">
        <el-input
          v-model="formData.account"
          placeholder="手机号码/用户名"
        ></el-input>
      </el-form-item>
     <!-- <el-form-item prop="imgCaptcha">
              <el-row :gutter="20">
                  <el-col :span="10">
                <span  style="display: inline-block;width: 130px;height: 38px;border: 1px solid #D7D7D7;"  >
          <img :src="imgCode"  style="width: 100%;height: 100%;cursor: pointer;" />
              </span>
              </el-col>
              <el-col :span="13">
                <el-input v-model="formData.imgCaptcha" type="text" placeholder="请输入图片验证码"></el-input>
                </el-col>
          </el-row>
          </el-form-item> -->
      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="formData.password"
          placeholder="密码"
        ></el-input>
      </el-form-item>

    </el-form>
    <!-- 短信登录 -->
    <el-form ref="thirdForm" v-else :model="formData" :rules="rules">
      <el-form-item prop="account">
        <div class="dxdl">
          <el-input class="dxdl-num-left" value="+86"></el-input>
          <el-input
            class="dxdl-num-right"
            v-model="formData.account"
            placeholder="手机号码"
            @input="(value) => value.length >= 11 ? getUserMember(value) : ''"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item v-show="showRegisterPwd" prop="password">
        <el-input
          type="password"
          v-model="formData.password"
          placeholder="密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <div class="dxdl">
          <el-input
            class="dxdl-code-left"
            v-model="formData.code"
            placeholder="请输入验证码"
          ></el-input>
          <!-- <el-button  class="dxdl-code-right"  @click="obj.validate()" :disabled="!this.codeFlag || !this.formData.accountBoole">{{codeBtnText}}</el-button> -->
          <el-button  class="dxdl-code-right"  @click="sendImageCode()" :disabled="!this.codeFlag || !this.formData.accountBoole">{{codeBtnText}}</el-button>
        </div>
      </el-form-item>
       <el-form-item prop="imgCaptcha" v-show="showImgCode && this.formData.accountBoole">
              <el-row :gutter="20">
                  <el-col :span="10">
                <span  style="display: inline-block;width: 130px;height: 38px;border: 1px solid #D7D7D7;"  >
          <img :src="imgCode" @click="sendImageCode()"  style="width: 100%;height: 100%;cursor: pointer;" />
              </span>
              </el-col>
              <el-col :span="13" style="padding-right: 0">
                <el-input v-model="formData.imgCaptcha" type="text" @input="(value) => value.length >= 4 ? getCodes() : ''" placeholder="请输入图片验证码" maxLength="4"></el-input>
              </el-col>
          </el-row>
          </el-form-item>
          <el-form-item v-show="mainWork && !invitedCode && ((showRegisterPwd && bd_vid) || (bd_vid && regMember))" prop="prPhone">
          <el-input
            type="text"
            v-model="formData.prPhone"
            placeholder="邀请人手机号"
          ></el-input>
        </el-form-item>
<!--  -->
<!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" >
    <el-dialog     title="图片验证码"
        :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
          <el-form-item prop="imgCaptcha"   >
              <el-row :gutter="20">
                  <el-col :span="10">
                <span  style="display: inline-block;width: 130px;height: 38px;border: 1px solid #D7D7D7;"  >
          <img :src="imgCode"  style="width: 100%;height: 100%;cursor: pointer;" @click="() => {imgCode2()}"/>
              </span>
              </el-col>
              <el-col :span="13">
                <el-input v-model="formData.imgCaptcha" type="text" placeholder="请输入图片验证码"></el-input>
                </el-col>
          </el-row>
          </el-form-item>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button   type="primary" @click="dialogVisible = false, onSubmit(formData.imgCaptcha)">确 定</el-button>
        </span>
    </el-dialog>
    </el-form> -->
    <!--  -->
    </el-form>

  </div>
</template>
<script>
import { captchaCode, getCode, userMember } from '../../network/api'
export default {
  props: {
    numOrSms: { default: true }, // 账号input为true,短信input为false
    showRegisterPwd: { default: false } // 注册页短信栏需要显示输入密码
  },
  data () {
    // 密码验证自定义
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'))
      } else {
        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(value)) {
          callback(new Error('由数字和字母组成,长度在6到16位之间'))
        }
        callback()
      }
    }

    return {
      formData: {
        account: '',
        password: '',
        imgCaptcha: '',
        code: '',
        accountBoole: null,
        passwordBoole: null,
        codeBoole: null
      },
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        prPhone: ''
      },
      imgCode: '',
      obj: '',
      dialogVisible: false,
      codeFlag: true,
      timer: null,
      codeBtnText: '获取验证码',
      vid: '',
      rules: {
        account: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          {
            pattern: /(^1[0-9]{10}$)/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ],
        prPhone: [
          { required: true, message: '请输入邀请人手机号码', trigger: 'blur' },
          {
            pattern: /(^1[0-9]{10}$)/,
            message: '请输入正确的邀请人手机号码',
            trigger: 'blur'
          }
        ],
        password: [{ validator: validatePass, trigger: 'blur' }],
        code: [{ required: true, message: '请输入手机验证码', trigger: 'blur' }],
        imgCaptcha: [
          { required: true, message: '请输入四位数验证码 ', trigger: 'change' }
        ]
      },
      showImgCode: false,
      number: 0,
      bd_vid: !sessionStorage.getItem('bd_vid'),
      regMember: false,
      mainWork: false,
      invitedCode: ''
    }
  },
  created () {
    this.getvid()
    this.mainWork = !!(this.$configList[window.location.host] === '1459435534555418624')
    if (this.$route.query && this.$route.query.invitedCode) {
      this.invitedCode = this.$route.query.invitedCode
    }
  },
  methods: {
    onSubmit (imgCaptcha) {
      // console.log(imgCaptcha.toString())
      // 发送提交
      captchaCode(imgCaptcha.toString()).then((res) => {
        // console.log(res)
        this.formData.imgCaptcha = ''
        if (res.code === '') {
          this.$notify.warning({
            title: '验证码不能为空',
            message: res.msg
          })
        } else if (res.code === 1) { // 验证码正确
          this.$notify({
            message: res.msg,
            type: 'success'
          })
          this.getCodes()
        } else if (res.code === 0) { // 请重新输入
          this.$notify.error({
            title: '验证码输入有误',
            message: res.msg
          })
        }
      })
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    getvid () {},
    verfiy () {
      // const a = 'resetpwd'
      // getSitepassword(a).then(res => {
      //   window
      //     .vaptcha({
      //       vid: res.data.vaptcha_key,
      //       type: 'invisible'
      //     })
      //     .then(function (vaptchaObj) {
      //       _this.getCodes()
      //       _this.verfiy()
      //       _this.obj = vaptchaObj // 将 VAPTCHA 验证实例保存到局部变量中
      //       验证码加载完成后将事件绑定到按钮
      //       调用 validate()方法的伪代码，将该方法的调用绑定在'click'事件上，实际开发中需要更改为合适的调用方式
      //       vaptchaObj.listen('pass', function () {
      //         // 验证成功进行后续操作
      //         // _this.getCodes()
      //         // _this.verfiy()
      //       })
      //     })
      // })
    },
    sendCode () {
      this.getCodes()
    },
    getUserMember (value) {
      userMember(value).then(res => {
        if (res.code === 1) {
          this.regMember = false
        } else {
          this.regMember = true
        }
        console.log(this.regMember, 'regMember')
      })
    },
    sendImageCode () {
      this.number += 1
      if (this.showRegisterPwd) {
        this.imgCode = this.$url + '/api/login/getImages?mobile=' + this.formData.account + '&event=register' + '?' + this.number
      } else {
        this.imgCode = this.$url + '/api/login/getImages?mobile=' + this.formData.account + '&event=mobilelogin' + '?' + this.number
      }
      this.showImgCode = true
      this.$notify({
        message: '请先输入图形验证码',
        type: 'success'
      })
    },
    // 获取验证码:需要先向父组件发送一个事件 只能由父组件来完成
    getCodes () {
      let time = 60
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (time > 0) {
            this.codeFlag = false
            this.codeBtnText = time + 's后重新发送'
            time--
          } else {
            clearInterval(this.timer)
            this.codeFlag = true
            this.codeBtnText = '获取验证码'
            this.timer = null
          }
        }, 1000)
      }
      // this.$emit('get-code')
      getCode(this.formData.account, this.showRegisterPwd ? 'register' : 'mobilelogin', this.formData.imgCaptcha).then(res => {
        if (res.code === 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.showImgCode = false
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
          this.sendImageCode()
        }
      })
    }
  },
  mounted () {
    // _this = this
    this.verfiy()
  },
  watch: {
    // 监听表单数据并传递给父组件
    formData: {
      handler (newData, oldData) {
        // 判断验证真假
        this.$refs.thirdForm.validateField('account', (valid) => {
          if (valid) {
            this.formData.accountBoole = false
          } else {
            this.formData.accountBoole = true
          }
        })
        this.$refs.thirdForm.validateField('password', (valid) => {
          if (valid) {
            this.formData.passwordBoole = false
          } else {
            this.formData.passwordBoole = true
          }
        })
        this.$refs.thirdForm.validateField('code', (valid) => {
          if (valid) {
            this.formData.codeBoole = false
          } else {
            this.formData.codeBoole = true
          }
        })
        // 向父组件发送事件传递数据
        this.$emit('form-data', newData)
      },
      deep: true
    }
  }

}

</script>

<style>
.dxdl {
   display: flex;
   flex-direction: row;
}
.dxdl-num-left {
  margin-right: -1px;
  width: 80px;
}
.dxdl-num-left input.el-input__inner,
.dxdl-code-left input.el-input__inner {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.dxdl-num-right input.el-input__inner,
.dxdl-code-right.el-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.dxdl-code-right.el-button {
  border-left: none;
}
/* --- */
/* --- */
</style>
